/*
    Some of the styles in the original front-page repo used plain .less files, not .module.less.
    Unfortunately, it is not easy to migrate some of the rules.
    Those rules are extracted in this files. With the reference to the original rule.
 */

/* Original front page rule: https://github.com/NerdWallet/front-page/blob/master/client/styles/front-page.less#L100-L133 */
@media (max-width: 767px) {
  .carousel .slick-slider .slick-list .slick-track .slick-slide {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .nerd-carousel
    .carousel
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    > div {
    flex: 1;
  }
}
.carousel .slick-slider .social-impact li button {
  width: 16px;
}
.carousel .slick-slider .social-impact li button::before {
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 16px;
  transform: scale(1.5);
  /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
  color: #9d9da1;
}
.carousel .slick-slider .social-impact li.slick-active button::before {
  /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
  color: #3d4045;
}

/* Original front page rule: https://github.com/NerdWallet/front-page/blob/master/client/components/press-page/press-logo-salad/press-logo-salad.less#L101-L103 */
.grow {
  flex-grow: 1;
}

/* Original front page rule: https://github.com/NerdWallet/front-page/blob/master/client/components/press-page/press-logo-salad/press-logo-salad.less#L76-L79 */
.press-logo-columns-6 img {
  object-fit: contain;
}

/* Original front page rule: https://github.com/NerdWallet/front-page/blob/master/client/components/press-page/press-logo-salad/press-logo-salad.less#L85-L87 */
@media (min-width: 48rem) {
  .press-logo-columns-6 {
    width: 16% !important;
  }
}
